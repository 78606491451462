<template>
  <div class="main">
    <!-- Password Recovery  -->
    <b-container class="thisContainer">
      <div
        class="bg-white passwordResetStyle animate__animated animate__fadeIn shadow"
      >
        <b-form @submit="handlePasswordReset">
          <h3 class="title">Password Reset</h3>
          <div class="inputWrapper">
            <b-input
              v-model="oldPassword"
              type="password"
              class="my-4 p-4 pl-5"
              placeholder="Temporary Password"
              required
            ></b-input>
            <i class="fas fa-lock"></i>
          </div>
          <div class="inputWrapper">
            <b-form-input
              v-model="newPassword"
              :state="pwValidation"
              type="password"
              class="my-4 p-4 pl-5"
              placeholder="New Password"
              required
            ></b-form-input>
            <b-form-invalid-feedback :state="pwValidation">
              <p class="m-2 text-danger" style="font-size: 15px">
                * Must be at least 8 characters
              </p>
            </b-form-invalid-feedback>
            <i class="fas fa-lock"></i>
          </div>
          <div class="inputWrapper">
            <b-form-input
              v-model="newPasswordAgain"
              :state="newPWValidation"
              type="password"
              class="my-4 p-4 pl-5"
              placeholder="Confirm New Password"
              required
            ></b-form-input>
            <b-form-invalid-feedback :state="newPWValidation">
              <p class="m-2 text-danger" style="font-size: 15px">
                * Two passwords do not match.
              </p>
            </b-form-invalid-feedback>
            <i class="fas fa-lock"></i>
          </div>
          <div class="text-center my-3">
            <button
              class="mybtn"
              style="font-size: 18px"
              :disabled="disableSubmitButton"
            >
              Submit
            </button>
          </div>
          <div class="text-center back">
            <h6 @click="handleGoBack">&lt; Back</h6>
          </div>
        </b-form>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ResetTempPassword",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
    };
  },
  computed: {
    disableSubmitButton() {
      let disbaledButton = false;
      if (this.newPassword != this.newPasswordAgain) {
        disbaledButton = true;
      }
      if (this.newPassword.length < 8) {
        disbaledButton = true;
      }
      return disbaledButton;
    },
    newPWValidation() {
      return (
        this.newPassword === this.newPasswordAgain &&
        this.newPasswordAgain !== ""
      );
    },
    pwValidation() {
      return this.newPassword.length >= 8;
    },
  },
  methods: {
    handlePasswordReset(event) {
      event.preventDefault();
      if (this.newPassword !== this.newPasswordAgain) {
        this.$notify.error({
          title: "Error !",
          message: "Your two password are not the same !",
        });
      } else {
        this.$axios
          .post("/Account/ChangePassword",{
            OldPassword: this.oldPassword,
            NewPassword: this.newPassword
          })
          .then((response) => {
            if (response.data === "Current Password incorrect") {
              this.$notify.error({
                title: "Error !",
                message: "Temporary password is incorrect !",
              });
            } else {
              this.$notify({
                title: "Success",
                message: "Change your password successfully !",
                type: "success",
              });
              this.$router.push("/home");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message: "Network Error !",
            });
          });
      }
    },
    handleGoBack() {
      this.$router.push("/login");
      this.$store.commit("setHasNotLogedInStatus", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.thisContainer {
  padding-top: 4%;
  width: 850px;
}

.title {
  font-size: 28px;
  color: #0394ca;
}

.inputWrapper {
  position: relative;
  font-size: 18px !important;
  i {
    color: #d2d2d2;
    position: absolute;
    font-size: 18px;
    top: 16px;
    left: 16px;
  }
}

.passwordResetStyle {
  width: 60%;
  margin: auto;
  padding: 60px 100px;
  border-radius: 10px;
}

.back {
  h6 {
    font-weight: 500;
    color: #0394ca;
    font-size: 18px;
  }
  h6:hover {
    cursor: pointer;
    color: #131313;
    transition: 0.4s;
  }
}

::placeholder {
  opacity: 0.3; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.3;
}

@media (max-width: 768px) {
  .thisContainer {
    padding-top: 0px;
    width: 100%;
    margin-top: -10px;
  }

  .passwordResetStyle {
    width: 100%;
    padding: 60px;
    margin-top: 10%;
  }
}
</style>
